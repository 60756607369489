import { createStore } from 'vuex'

import * as quanbuApi from '@/api/quanbu'

const store = createStore({
  //对象方式
  state:{ 
    mallUrl:'',
    shopUrl:'',
    owUrl:''
  },

  //同步操作
  mutations:{
    // 商家店铺链接
    SET_dianpuUrl(state,mallUrl) {
      state.mallUrl = mallUrl
    },
    // 商家后台链接
    SET_dianpuhotaiUrl(state,shopUrl) {
      state.shopUrl = shopUrl
    },
    // 商家后台链接
    SET_owUrl(state,owUrl) {
      state.owUrl = owUrl
    },

  },
  //异步操作
  actions:{
    // 获取店铺、商家后台地址
    systemParams({ commit, state }) {
      return new Promise((resolve, reject) => {
        let data = ['mallUrl','shopUrl','ow']
        quanbuApi.systemParams(data).then((res)=>{
          console.log(res,'sssssss')
          if(res.code==200){
            store.commit('SET_dianpuUrl',res.data.mallUrl)
            store.commit('SET_dianpuhotaiUrl',res.data.shopUrl)
            store.commit('SET_owUrl',res.data.ow)
          }

        })
        
      })
    },
  },
  //getters相当于计算属性
  getters:{
    
  }
})

export default store

<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <router-view />
  <!-- <HelloWorld /> -->
</template>

<script>
// import HelloWorld from './view/login.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
}

.zhezhaoceng {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #000000a6;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>

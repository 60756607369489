import request from '@/utils/request'


// 获取系统参数
export function systemParams(data) {
  return request({
    url: '/system/params',
    method: 'post',
    data:data
  })
}

// 文章
export function cosText(data) {
  return request({
    url: '/cos/text',
    method: 'GET',
    params:data
  })
}

// 文章
export function sysArticleList(data) {
  return request({
    url: '/sys/article/list',
    method: 'GET',
    params:data
  })
}



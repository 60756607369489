import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/AboutTheProject'
    },
    {
        path: '/officialWebsite',
        name: 'officialWebsite',
        component: () => import('@/view/home.vue'),
    },
    {
        path: '/AboutTheProject',
        name: 'AboutTheProject',
        component: () => import('@/view/AboutTheProject.vue'),
    },
    {
        path: '/HelloWorld',
        name: 'HelloWorld',
        component: () => import('@/view/HelloWorld.vue'),
    },
    
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router

// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

// import { createApp } from 'vue'
// import App from './App.vue'
// // import router from './router';
// // element
// import ElementPlus from 'element-plus';
// import 'element-plus/theme-chalk/index.css'

// const app = createApp(App)
// //   .use(router)
//   .use(ElementPlus);
  
// router.isReady().then(() => {
//   app.mount('#app');
// });



// createApp(App).mount('#app')


// main.ts
import { createApp } from 'vue'
import ElementPlus from 'element-plus' //全局引入
import 'element-plus/dist/index.css'
import App from './App.vue'
const app = createApp(App)
app.use(ElementPlus)

import store from './store'
app.use(store) //注册vuex

import router from './router/index'
app.use(router)

app.mount('#app')


// 全局拦截
router.beforeEach((to, from, next) => {
  console.log(store.state.dianpuUrl,'dianpuUrl')
  if(store.state.mallUrl==''||store.state.shopUrl==''){

    store.dispatch('systemParams').then(res => {
      console.log(res,'11111')
      if(res.code==200){
        next()
      }
      // next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
    })
  }
  next()

})
